// Namespaces
API = {};
CFG = {};

CFG.Layout = {};

// Layout changing points
CFG.Layout.SmallScreen = 600;
CFG.Layout.MediumScreen = 992;
CFG.Layout.LargeScreen = 1200;

API.jQueryLoaded = !!window.jQuery;

API.InitializeJqueryWrapper = function(plugin, pluginName, classRef) {
  jQuery.fn[pluginName] = function(methodOrOptions) {
    // Call plugin method if valid method name is passed in
    if (plugin.prototype[methodOrOptions]) {
      let params = Array.prototype.slice.call( arguments, 1 );

      // Getter methods
      if (methodOrOptions.slice(0,3) === 'get') {
        let instance = this.first()[0][classRef];
        return instance[methodOrOptions].apply(instance, params);
      }

      // Void methods
      return this.each(function() {
        let instance = this[classRef];
        instance[methodOrOptions].apply(instance, params);
      });

    // Initialize plugin if options or no argument is passed in
    } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {
      plugin.init(this, arguments[0]);
      return this;

    }

    // Return error if an unrecognized  method name is passed in
    jQuery.error(`Method ${methodOrOptions} does not exist on jQuery.${pluginName}`);
  };
};

flowplayer.conf.share = false;
/*flowplayer.conf.ima = {
  adRules: "https://webcams.comanet.cz/vast_rules.php"
};*/

API.ROB = {};
API.ROB.Cache = [];

API.ROB.Register = function(what){
  API.ROB.Cache.push(what);
}

API.ROB.Execute = function(){
  for (var i = 0; i < API.ROB.Cache.length; i++) {
    API.ROB.Cache[i]();
  }
}

API.Youtube = {};
API.Youtube.Cache = [];
